body {
  font-size: 17px;
}

a {
  @apply font-semibold;
  @apply font-mono;
  @apply no-underline;
  @apply text-pink-600;
  @apply hover:text-pink-500;
  @apply hover:underline;
}

blockquote {
  @apply pl-6;
  @apply my-8;
  @apply text-lg;
  @apply font-normal;
  @apply italic;
  @apply text-gray-800;
  @apply border-l-4;
  @apply border-blue-400;
}

.line-added {
  @apply bg-green-500;
}
code {
  @apply px-2;
  @apply py-px;
  @apply text-sm;
  @apply bg-code;
  @apply text-white;
  @apply shadow;
  @apply border;
  @apply border-pink-200;
  @apply font-mono;
  @apply rounded;
}
/*
code.hljs {
  @apply p-0;
  @apply bg-transparent;
  @apply border-none;

  & .hljs-comment,
  & .hljs-keyword,
  & .hljs-meta {
    @apply font-normal;
    @apply not-italic;
  }
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-mono;
  @apply mt-8;
  @apply mb-4;
  @apply text-gray-900;
  @apply leading-tight;

  &:first-child {
    @apply mt-0;
  }
}

h1 {
  @apply text-3xl;
  @apply font-semibold;
}

h2 {
  @apply text-2xl;
  @apply font-semibold;
}

h3 {
  @apply text-xl;
  @apply font-semibold;
}

h4 {
  @apply text-lg;
  @apply font-normal;
}

h5 {
  @apply text-lg;
  @apply font-normal;
}

h6 {
  @apply text-base;
  @apply font-light;
}

hr {
  @apply my-12;
  @apply border-b;
  @apply border-blue-200;
  @apply rounded-full;
}

li {
  & ul,
  & ol {
    @apply ml-8;
    @apply my-0;
  }
}

ol,
ul {
  @apply my-4;
}

ol {
  @apply list-decimal;
}

ul {
  @apply list-disc;
}

p {
  @apply my-3;

  @screen md {
    @apply my-6;
  }
}

pre {
  @apply text-shadow-code;
  /* @apply p-4; */
  /* @apply my-6; */
  @apply text-base;
  /* @apply bg-gray-200; */
  @apply leading-loose;
  @apply rounded;
  @apply shadow;
  @apply overflow-x-auto;

  /* & code {
    @apply block;
    @apply p-0;
    @apply bg-transparent;
  } */
}

::selection {
  @apply text-pink-900;
  @apply bg-pink-200;
}

[x-cloak] {
  display: none !important;
}
